import Icons from 'Icons';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { validateProducts } from 'features/orders/helpers/orderValidationHelper';
import { OrderWindowProduct } from 'features/orders/models/OrderWindowProduct';
import { ProductOption } from 'features/orders/models/ProductOption';
import ordersApi from 'features/orders/orders.api';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import { SalesOrderDetail } from 'features/sales/models/SalesOrderDetail';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MeasurementDisplay from '../../../../components/MeasurementDisplay/MeasurementDisplay';
import OrderProductEditModal from '../OrderProductEditModal/OrderProductEditModal';
import OrderProductOptionsModal from '../OrderProductOptionsModal/OrderProductOptionsModal';
import OrderUpdatesMessagePanelLegacy from '../OrderUpdatesMessagePanel/OrderUpdatesMessagePanelLegacy';
import ProductPathDisplay from '../ProductPathDisplay/ProductPathDisplay';
import './OrderProductModal.scss';

// This legacy component is a hold-over from when the Sales Order and Purchase Order models were similar enough to share a component.
// It is anticipated that once the Sales Order models are updated that the updated version of this component can once again be used
// to display both models, allowing this legacy component to be removed.

export default function OrderProductModalLegacy({
    model,
    order,
    options,
    isLoading,
    isError,
    close,
}: {
    model?: OrderWindowProduct;
    order?: SalesOrderDetail;
    options?: ProductOption[];
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const navigate = useNavigate();
    const dialogManager = useDialogManager();
    const [deleteMutation] = ordersApi.useOrderWindowDeleteMutation();
    const [cloneMutation] = ordersApi.useOrderWindowCloneMutation();

    const [showEditProductModal, setShowEditProductModal] = useState(false);
    const [showEditOptionsModal, setShowEditOptionsModal] = useState(false);

    const { routeBase, entityTitle } = useOrderPresentation();

    const isReadOnly = order?.isReadOnly;

    /** Clone product - this actually clones the whole window */
    const cloneProduct = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Clone product',
            message:
                'A copy of this product will be created and added to this order. Are you sure?',
            acceptLabel: 'Yes, clone now',
        });
        if (confirm && model) {
            const productId = await dialogManager.showLoadingWhile(
                cloneMutation({
                    orderId: model.order_id,
                    windowId: model.order_window_id,
                }).unwrap(),
            );
            navigate(`${routeBase}/${model.order_id}/products/${productId}`);
        }
    }, [cloneMutation, dialogManager, model, navigate, routeBase]);

    /** Delete product - this actually deletes the whole window */
    const deleteProduct = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Delete product',
            message: 'Are you sure you want to delete this product?',
            acceptLabel: 'Yes, delete it',
            acceptButtonType: 'Danger',
        });
        if (model && confirm) {
            await dialogManager.showLoadingWhile(
                deleteMutation({
                    orderId: model.order_id,
                    windowId: model.order_window_id,
                }),
            );
            close?.();
        }
    }, [close, deleteMutation, dialogManager, model]);

    const menuItems: MyMenuButtonItem[] = useMemo(
        () => [
            {
                label: 'Delete product',
                IconLeft: Icons.Archive,
                onClick: deleteProduct,
            },
        ],
        [deleteProduct],
    );

    const errors = useMemo(() => (model ? validateProducts([model]) : []), [model]);
    const isOptionsSelected = useMemo(
        () => errors.findIndex(e => e.error === 'OPTIONS_NOT_SELECTED') === -1,
        [errors],
    );
    const displayErrors = useMemo(
        () => errors.filter(e => e.error !== 'OPTIONS_NOT_SELECTED'),
        [errors],
    );

    return (
        <MyModal
            className="OrderProductModal"
            close={close}
            mobileTitle={entityTitle}
            isLoading={isLoading}
            isError={isError}
            header={
                <div className="OrderProductModal__Header__Inner">
                    <PageHeader
                        className="OrderProductModal__PageHeader"
                        title="Product"
                    >
                        {!isReadOnly && (
                            <>
                                <MyButton
                                    label="Clone"
                                    title="Create a copy of this product"
                                    IconLeft={Icons.Clone}
                                    buttonType="Hollow"
                                    onClick={cloneProduct}
                                />
                                <MyMenuKebabButton menuItems={menuItems} />
                            </>
                        )}
                    </PageHeader>

                    <ProductPathDisplay product={model} />

                    {order?.hasPendingUpdates && <OrderUpdatesMessagePanelLegacy model={order} />}

                    {/* Options prompt */}
                    {!isOptionsSelected && !order?.hasPendingUpdates && (
                        <MessagePanel
                            messageType={'info'}
                            className="OrderProductModal__OptionsPrompt"
                            actions={
                                <MyButton
                                    className="OrderProductModal__OptionsPrompt__Button"
                                    label="Show options"
                                    IconRight={Icons.ChevronRight}
                                    onClick={() => setShowEditOptionsModal(true)}
                                />
                            }
                        >
                            Specify options to finish setting up this product
                        </MessagePanel>
                    )}

                    {/* Validation errors */}
                    {displayErrors.length > 0 && (
                        <MessagePanel messageType={'error'}>
                            {errors.map((err, i) => (
                                <div key={i}>{err.message}</div>
                            ))}
                        </MessagePanel>
                    )}

                    {/* Special case - product was manually deselected using old UI
                    This isnt possible in the new UI */}
                    {errors.length === 0 && model?.is_selected === 0 && (
                        <MessagePanel messageType={'info'}>
                            This product has been disabled
                        </MessagePanel>
                    )}
                </div>
            }
        >
            {model && order && (
                <>
                    <div className="OrderProductModal__Section">
                        <div className="OrderProductModal__Section__Header">
                            <h2>Details</h2>
                            {!isReadOnly && (
                                <MyButton
                                    className="OrderProductModal__Section__EditButton"
                                    label="Edit"
                                    IconLeft={Icons.Edit}
                                    buttonType="Hollow"
                                    size="small"
                                    onClick={() => setShowEditProductModal(true)}
                                />
                            )}
                        </div>

                        <PropertyContainer layout="table">
                            <PropertyDisplay
                                label="Window or group"
                                value={model.details}
                            />

                            {model.has_quantity === 1 && (
                                <PropertyDisplay
                                    label="Quantity"
                                    value={
                                        model.quantity === 1 ? (
                                            '1'
                                        ) : (
                                            <span className="OrderProductModal__QuantityBadge">
                                                {model.quantity}
                                            </span>
                                        )
                                    }
                                />
                            )}
                            {model.has_width === 1 && (
                                <PropertyDisplay
                                    label="Width"
                                    value={<MeasurementDisplay value={model.width} />}
                                />
                            )}
                            {model.has_height === 1 && (
                                <PropertyDisplay
                                    label="Height"
                                    value={<MeasurementDisplay value={model.height} />}
                                />
                            )}
                            <PropertyDisplay
                                label="Notes"
                                value={model.notes}
                                verticalAlign="top"
                            />
                        </PropertyContainer>
                    </div>

                    <div className="OrderProductModal__Section">
                        <div className="OrderProductModal__Section__Header">
                            <h2>Options</h2>
                            {!isReadOnly && (
                                <MyButton
                                    className="OrderProductModal__Section__EditButton"
                                    label="Edit"
                                    IconLeft={Icons.Edit}
                                    buttonType="Hollow"
                                    size="small"
                                    onClick={() => setShowEditOptionsModal(true)}
                                />
                            )}
                        </div>
                        {options?.length === 0 && (
                            <div className="OrderProductModal__NoOptions">
                                Options not selected yet
                            </div>
                        )}
                        <div className="OrderProductModal__OptionContainer">
                            {options?.map((opt, i) => (
                                <OptionDisplay
                                    key={i}
                                    option={opt}
                                />
                            ))}
                        </div>
                    </div>

                    {showEditProductModal && (
                        <OrderProductEditModal
                            model={model}
                            close={() => setShowEditProductModal(false)}
                        />
                    )}
                    {showEditOptionsModal && (
                        <OrderProductOptionsModal
                            model={model}
                            close={() => setShowEditOptionsModal(false)}
                        />
                    )}
                </>
            )}
        </MyModal>
    );
}

function OptionDisplay({ option }: { option: ProductOption }) {
    return (
        <div className="OrderProductModal__Option">
            <div className="OrderProductModal__Option__Label">{option.label}</div>
            <div className="OrderProductModal__Option__Value">{option.value}</div>
        </div>
    );
}
