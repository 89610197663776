import z from 'zod';
import { DealFlagSchema } from './DealFlag';

export const DealSchema = z.object({
    id: z.string(),
    tuid: z.string(),
    workflowId: z.string(),
    customerName: z.string(),
    description: z.string(),
    // salesRep: z.string(),
    statusId: z.string(),
    sortOrder: z.string(),
    isArchived: z.boolean().optional(),
    context: z.object({
        flags: z.array(DealFlagSchema),
    }),
});

export type Deal = z.infer<typeof DealSchema>;
