import { dateOnly, dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';
import PurchaseOrderStatus from '../enums/PurchaseOrderStatus';

export const PurchaseOrderDetailSchema = z
    .object({
        id: z.number(),
        tuid: z.string(),
        status: z.nativeEnum(PurchaseOrderStatus),
        createdAt: dateTimeInstant(),
        isArchived: z.boolean(),
        manufacturerId: z.number(),

        // Editable fields
        salesRep: z.string(),
        sidemark: z.string().nullable(),
        customerDetails: z.string().nullable(),
        notes: z.string(),

        // Pricing fields
        totalCost: z.number(),
        totalQuantity: z.number(),

        context: z.object({
            parentOrder: z
                .object({
                    id: z.number(),
                    isSalesOrder: z.boolean(),
                })
                .optional(),
            latestRevision: z
                .object({
                    id: z.number(),
                    tuid: z.string(),
                })
                .optional(),
            orderDocument: z
                .object({
                    id: z.number(),
                    url: z.string(),
                })
                .optional(),
            pendingUpdates: z.object({
                hasPendingUpdates: z.boolean(),
                orderUpdates: z.object({
                    hasSurchargesUpdates: z.boolean(),
                    hasFreightUpdates: z.boolean(),
                    hasInstallationUpdates: z.boolean(),
                }),
                products: z.array(
                    z.object({
                        productId: z.number(),
                        productName: z.string(),
                        hasCostPaddingUpdates: z.boolean(),
                        hasFabricUpdates: z.boolean(),
                        hasFreightUpdates: z.boolean(),
                        hasInstallationUpdates: z.boolean(),
                        hasRuleUpdates: z.boolean(),
                        hasSurchargesUpdates: z.boolean(),
                        hasPricingUpdates: z.boolean(),
                    }),
                ),
            }),
            shippingAddress: z.string(),
            requiredDate: dateOnly().nullable(),
            shippingMethod: z
                .object({
                    name: z.string(),
                })
                .optional(),
        }),
    })
    .transform(model => {
        return {
            ...model,
            isPurchase: true as const,
            isSale: false as const,
            isReadOnly:
                model.context.pendingUpdates.hasPendingUpdates ||
                model.status === PurchaseOrderStatus.Submitted ||
                model.isArchived,
        };
    });

export type PurchaseOrderDetail = z.infer<typeof PurchaseOrderDetailSchema>;
