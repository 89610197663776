import { validateProducts } from 'features/orders/helpers/orderValidationHelper';
import { OrderWindowProduct } from 'features/orders/models/OrderWindowProduct';
import useGetProductManufacturerOrders from 'features/purchases/helpers/useGetProductManufacturerOrders';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import { SalesOrderDetail } from 'features/sales/models/SalesOrderDetail';
import React, { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { formatCurrency } from 'utils/helpers';
import { ManufacturerOrderStatusBadge } from '../../../purchases/components/ManufacturerOrderStatusBadge/ManufacturerOrderStatusBadge';
import { ProductDimensionDisplay } from '../ProductDimensionDisplay/ProductDimensionDisplay';
import './OrderProductRow.scss';

export function OrderProductRow({
    product,
    order,
    to,
    showManufacturerStatus,
}: {
    product: OrderWindowProduct;
    order: SalesOrderDetail | PurchaseOrderDetail;
    to?: string;
    showManufacturerStatus?: boolean;
}) {
    const optionsString = useMemo(
        () => product.product_option_selects.map(s => s.name).join(', '),
        [product.product_option_selects],
    );
    const [urlParams] = useSearchParams();

    const errors = useMemo(() => validateProducts([product]), [product]);

    return (
        <Link
            className="OrderProductRow"
            to={to ?? `products/${product.id}?${urlParams}`}
        >
            {/* Right section contain price/quantity and manufacturer status
            It is floated right to allow other text to wrap around it
            For this reason it needs to appear first in the dom */}
            <div className="OrderProductRow__Right">
                <div className="OrderProductRow__Right__Price">
                    {formatCurrency(
                        order.isSale
                            ? product.final_price.discounted_retail_price
                            : product.final_price.cost_price,
                    )}
                </div>

                {/* Manufacturer order statuses - shows for purchase orders only */}
                {order.isPurchase && showManufacturerStatus && (
                    <ManufacturerOrderStatuses
                        order={order}
                        productId={product.id}
                    />
                )}
            </div>

            <div className="OrderProductRow__Main">
                {product.details && (
                    <>
                        {/* When details is set, use it as the main label */}
                        <div className="OrderProductRow__Main__GroupName">{product.details}</div>
                    </>
                )}

                <div className="OrderProductRow__Main__ProductName">
                    {product.brands_name}: {product.master_products_name ?? product.products_name}
                </div>
                <div className="OrderProductRow__Main__Badges">
                    <ProductDimensionDisplay product={product} />

                    {product.has_quantity === 1 && product.quantity > 0 && (
                        <div className="OrderProductRow__Main__Quantity">
                            <label>Qty:</label> {product.quantity}
                        </div>
                    )}
                </div>

                {optionsString && (
                    <div className="OrderProductRow__Main__Options">{optionsString}</div>
                )}
                {product.notes && (
                    <div className="OrderProductRow__Main__Notes">{product.notes}</div>
                )}

                {/* Validation errors */}
                {errors.length > 0 && (
                    <div className="OrderProductRow__Main__ValidationErrors">
                        {errors.map((err, index) => (
                            <div
                                key={index}
                                className="OrderProductRow__Main__ValidationErrors__Error"
                            >
                                {err.message}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Link>
    );
}

function ManufacturerOrderStatuses({
    order,
    productId,
}: {
    order: PurchaseOrderDetail;
    productId: number;
}) {
    const orders = useGetProductManufacturerOrders({ order, productId });
    if (!orders || orders.length === 0) {
        return null;
    }

    return (
        <div className="OrderProductRow__ManufacturerOrderStatuses">
            {orders?.map(mfo => (
                <ManufacturerOrderStatusBadge
                    key={mfo.id}
                    manufacturerOrder={mfo}
                    showTooltip="full"
                />
            ))}
        </div>
    );
}
