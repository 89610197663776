import { useFullscreenLayout } from 'components/Layout/Layout';
import LayoutScrollArea from 'components/LayoutScrollArea/LayoutScrollArea';
import MyButton from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import DealCreateModal from 'features/deals/components/DealCreateModal/DealCreateModal';
import DealsKanban from 'features/deals/components/DealsKanban/DealsKanban';
import dealsApi from 'features/deals/deals.api';
import {
    selectWorkflowGeneric,
    selectWorkflowSales,
    selectWorkflowWarranty,
} from 'features/deals/deals.slice';
import WorkflowType, { WorkflowTypeDisplay } from 'features/deals/enums/WorkflowType';
import DealPresentation, { useDealPresentation } from 'features/deals/providers/DealPresentation';
import Icons from 'Icons';
import React, { useState } from 'react';
import { Outlet } from 'react-router';
import { useAppSelector } from 'store/hooks';
import { usePageTitle } from 'utils/locationHelpers';

export default function DealsPage({ workflowType }: { workflowType: WorkflowType }) {
    usePageTitle(WorkflowTypeDisplay.display(workflowType));
    useFullscreenLayout();

    return (
        <>
            <DealPresentation workflowType={workflowType}>
                <Content />
                <Outlet />
            </DealPresentation>
        </>
    );
}

function Content() {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const { workflowType, entityTitle } = useDealPresentation();

    const salesWorkflow = useAppSelector(selectWorkflowSales);
    const warrantyWorkflow = useAppSelector(selectWorkflowWarranty);
    const genericWorkflow = useAppSelector(selectWorkflowGeneric);

    const workflow =
        workflowType === WorkflowType.Sales
            ? salesWorkflow
            : workflowType === WorkflowType.Warranty
            ? warrantyWorkflow
            : genericWorkflow;

    const workflowDetailQuery = dealsApi.useWorkflowDetailQuery(workflow?.id ?? '', {
        skip: !workflow,
    });

    return (
        <>
            <PageHeader title={WorkflowTypeDisplay.display(workflowType)}>
                <MyButton
                    onClick={() => setIsCreateModalOpen(true)}
                    label={`Add ${entityTitle}`}
                    IconLeft={Icons.Plus}
                    buttonType="Accent"
                />
            </PageHeader>
            {workflowDetailQuery.data && (
                <>
                    <LayoutScrollArea>
                        <DealsKanban workflow={workflowDetailQuery.data} />
                    </LayoutScrollArea>
                    {isCreateModalOpen && (
                        <DealCreateModal
                            workflow={workflowDetailQuery.data}
                            close={() => setIsCreateModalOpen(false)}
                        />
                    )}
                </>
            )}
        </>
    );
}
