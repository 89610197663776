import MeasurementDisplay from 'components/MeasurementDisplay/MeasurementDisplay';
import { OrderWindowProduct } from 'features/orders/models/OrderWindowProduct';
import React from 'react';
import './ProductDimensionDisplay.scss';

export function ProductDimensionDisplay({ product }: { product: OrderWindowProduct }) {
    if (product.has_width && product.has_height) {
        return (
            <div className="ProductDimensionDisplay">
                <div className="ProductDimensionDisplay__Badge">
                    <MeasurementDisplay value={product.width} />
                    &nbsp;&times;&nbsp;
                    <MeasurementDisplay value={product.height} />
                </div>
            </div>
        );
    }
    if (product.has_width) {
        return (
            <div className="ProductDimensionDisplay">
                <div className="ProductDimensionDisplay__Badge">
                    <MeasurementDisplay
                        value={product.width}
                        showMetricUnit
                    />
                </div>
            </div>
        );
    }
    if (product.has_height) {
        return (
            <div className="ProductDimensionDisplay">
                <div className="ProductDimensionDisplay__Badge">
                    <MeasurementDisplay
                        value={product.height}
                        showMetricUnit
                    />
                </div>
            </div>
        );
    }
    return null;
}
