import MyEditModal from 'components/MyEditModal/MyEditModal';
import { selectCurrentUser } from 'features/auth/auth.slice';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import {
    PurchaseOrderEditable,
    PurchaseOrderEditableFactory,
} from 'features/purchases/models/PurchaseOrderEditable';
import purchasesApi from 'features/purchases/purchases.api';
import { selectCurrentCompany } from 'features/settings/settings.slice';
import React, { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import PurchaseOrderEditFields from '../PurchaseOrderEditFields/PurchaseOrderEditFields';
import './PurchaseOrderEditModal.scss';

export default function PurchaseOrderEditModal({
    order,
    close,
}: {
    order: PurchaseOrderDetail;
    close?: () => void;
}) {
    const [saveMutation, saveMutationState] = purchasesApi.usePurchaseOrderUpdateMutation();
    const { entityTitle } = useOrderPresentation();
    const company_id = useAppSelector(selectCurrentCompany)?.id;
    const employee_id = useAppSelector(selectCurrentUser)?.id ?? null;

    const model = useMemo(
        () =>
            company_id
                ? PurchaseOrderEditableFactory.create({
                      company_id,
                      employee_id,
                      id: order.id,
                      sidemark: order.sidemark,
                      sales_rep_override: order.salesRep,
                      details: order.notes,
                      manufacturer_id: order.manufacturerId,
                  })
                : undefined,
        [
            company_id,
            employee_id,
            order.id,
            order.manufacturerId,
            order.notes,
            order.salesRep,
            order.sidemark,
        ],
    );

    const save = async (editModel: PurchaseOrderEditable) => {
        await saveMutation(editModel).unwrap();

        close?.();
    };

    return (
        <MyEditModal
            className="PurchaseOrderEditModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="Edit details"
            titleContext={order.tuid}
            mobileTitle={entityTitle}
        >
            {({ editModel, updateField, isSaving }) => (
                <PurchaseOrderEditFields
                    editModel={editModel}
                    isSaving={isSaving}
                    updateField={updateField}
                />
            )}
        </MyEditModal>
    );
}
