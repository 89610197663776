import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import dealsApi from './deals.api';
import WorkflowType from './enums/WorkflowType';
import { Workflow } from './models/Workflow';

type DealsState = {
    workflows: Workflow[] | undefined;
};

const getInitialState = (): DealsState => {
    return {
        workflows: undefined,
    };
};

const slice = createSlice({
    name: 'deals',
    initialState: getInitialState(),
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(dealsApi.endpoints.workflowList.matchFulfilled, (state, action) => {
            state.workflows = action.payload;
        });
    },
});

// export const {} = slice.actions;

export default slice.reducer;

export const selectWorkflowSales = (state: RootState) =>
    state.deals.workflows?.find(w => w.workflowType === WorkflowType.Sales);
export const selectWorkflowWarranty = (state: RootState) =>
    state.deals.workflows?.find(w => w.workflowType === WorkflowType.Warranty);
export const selectWorkflowGeneric = (state: RootState) =>
    state.deals.workflows?.find(w => w.workflowType === WorkflowType.Generic);
