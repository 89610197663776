import { FlagValue } from 'features/deals/models/FlagValue';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './DealFlagPill.scss';

export default function DealFlagPill({
    value,
    size = 'small',
}: {
    value: FlagValue;
    size: 'small' | 'large';
}) {
    return (
        <div
            key={value.id}
            className={coalesceClassNames('DealFlagPill', size, value.appearance)}
        >
            {value.value}
        </div>
    );
}
