import Dialog from 'components/Dialogs/Dialog';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import { OrderWindowProduct } from 'features/orders/models/OrderWindowProduct';
import ordersApi from 'features/orders/orders.api';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import { useDialogManager } from 'providers/DialogManager';
import React, { useState } from 'react';
import sanitize from 'sanitize-html';
import { ApiTagType } from 'services/api';
import { useAppDispatch } from 'store/hooks';
import { useWindowOnMessage } from 'utils/windowMessage';
import { ProductDimensionDisplay } from '../ProductDimensionDisplay/ProductDimensionDisplay';
import ProductPathDisplay from '../ProductPathDisplay/ProductPathDisplay';
import './OrderProductOptionsModal.scss';

export default function OrderProductOptionsModal({
    model,
    close,
}: {
    model: OrderWindowProduct;
    close?: () => void;
}) {
    const dispatch = useAppDispatch();
    const dialogManager = useDialogManager();

    const [hasFrameLoaded, setHasFrameLoaded] = useState(false);

    const { isSales } = useOrderPresentation();

    useWindowOnMessage(e => {
        if (e.data?.type === 'didSave') {
            // Options were saved inside the iframe, invalidate data
            const listTag = isSales ? ApiTagType.SalesOrderList : ApiTagType.PurchaseOrderList;
            const detailTag = isSales
                ? ApiTagType.SalesOrderDetail
                : ApiTagType.PurchaseOrderDetail;

            dispatch(
                ordersApi.util.invalidateTags([
                    listTag,
                    { type: detailTag, id: model.order_id },
                    { type: ApiTagType.OrderWindowList, id: model.order_id },
                    { type: ApiTagType.OrderProductOptions, id: model.id },
                ]),
            );
        }
        if (e.data?.type === 'openUrl') {
            // Open the given url in a new browser tab
            const url = e.data.data as string;
            if (url) {
                window.open(url, '_blank');
            }
        }
        if (e.data?.type === 'closeModal') {
            // Iframe requested that the modal be closed
            close?.();
        }
        if (e.data?.type === 'error') {
            // There was an error in the options form inside the modal
            // display a dialog with the error message
            const data = e.data.data as {
                heading?: string;
                message?: string;
            };
            dialogManager.custom(ErrorDialog, { title: data.heading, message: data.message });
        }
    });

    return (
        <MyModal
            className="OrderProductOptionsModal"
            mobileTitle={'Product'}
            close={close}
            fullHeight
            disableBackgroundClose
            header={
                <PageHeader
                    className="OrderProductOptionsModal__PageHeader"
                    title="Product Options"
                    titleContext={model?.details}
                    subtitle={
                        <>
                            <ProductPathDisplay product={model} />
                            {(model.has_width === 1 ||
                                model.has_height === 1 ||
                                model.has_quantity === 1) && (
                                <div className="OrderProductOptionsModal__ProductMetrics">
                                    <ProductDimensionDisplay product={model} />
                                    {model.has_quantity === 1 && (
                                        <div className="OrderProductOptionsModal__QuantityBadge">
                                            Qty: {model?.quantity}
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    }
                />
            }
        >
            <iframe
                className="OrderProductOptionsModal__ContentFrame"
                src={`${window.IFRAME_BASE_URL}/product-options/${model?.id}`}
                onLoad={() => setHasFrameLoaded(true)}
            />
            {!hasFrameLoaded && (
                <div className="OrderProductOptionsModal__LoadingOverlay">
                    <MyLinearProgress delay={200} />
                </div>
            )}
        </MyModal>
    );
}

function ErrorDialog({
    title,
    message,
    close,
}: {
    title?: string;
    message?: string;
    close?: () => void;
}) {
    // sanitize html to prevent any nasty stuff coming through
    const sanitizeOptions: sanitize.IOptions = {};
    const safeTitle = title ? sanitize(title, sanitizeOptions) : undefined;
    const safeMessage = message ? sanitize(message, sanitizeOptions) : undefined;

    return (
        <Dialog
            className="OrderProductOptionsModal__ErrorDialog"
            close={close}
        >
            <div
                className="OrderProductOptionsModal__ErrorDialog__Title"
                dangerouslySetInnerHTML={{ __html: safeTitle ?? '' }}
            />
            <div
                className="OrderProductOptionsModal__ErrorDialog__Message"
                dangerouslySetInnerHTML={{ __html: safeMessage ?? '' }}
            />
            <div className="DialogManager__dialog__buttons">
                <MyButton
                    label="OK"
                    buttonType="Primary"
                    onClick={close}
                />
            </div>
        </Dialog>
    );
}
