import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum WorkflowType {
    Sales = 'SALES',
    Generic = 'GENERIC',
    Warranty = 'WARRANTY',
}

export default WorkflowType;

const labels: EnumDisplayLabels<typeof WorkflowType> = {
    Sales: 'Sales',
    Generic: 'Generic',
    Warranty: 'Warranty',
};

export const WorkflowTypeDisplay = createDisplayEnum(WorkflowType, labels);
