import z from 'zod';
import { FlagValueSchema } from './FlagValue';

export const FlagDefinitionSchema = z.object({
    id: z.string(),
    name: z.string(),
    values: z.array(FlagValueSchema),
});

export type FlagDefinition = z.infer<typeof FlagDefinitionSchema>;
