import { dateTimeInstant } from 'utils/dateSchemas';
import { mergeDeep } from 'utils/helpers';
import z from 'zod';

export const DealNoteSchema = z.object({
    id: z.string(),
    content: z.string(),
    createdAt: dateTimeInstant(),
    createdBy: z.number(),
    updatedAt: dateTimeInstant().nullable(),
    updatedBy: z.number().nullable(),
    context: z.object({
        createdBy: z.object({
            id: z.number(),
            firstName: z.string(),
            lastName: z.string(),
        }),
        updatedBy: z
            .object({
                id: z.number(),
                firstName: z.string(),
                lastName: z.string(),
            })
            .nullable(),
    }),
});

export type DealNote = z.infer<typeof DealNoteSchema>;

export class DealNoteFactory {
    static create(template?: Partial<DealNote>): DealNote {
        const defaults: DealNote = {
            id: '',
            content: '',
            createdAt: '',
            createdBy: 0,
            updatedAt: null,
            updatedBy: null,
            context: {
                createdBy: {
                    id: 0,
                    firstName: '',
                    lastName: '',
                },
                updatedBy: null,
            },
        };

        return mergeDeep(defaults, template);
    }
}
