import { createSlice } from '@reduxjs/toolkit';
import { ModulesConfig } from 'features/settings/models/ModulesConfigSchema';
import { RootState } from 'store/store';
import { setMeasureSystem } from 'utils/measurementHelper';
import { Company } from './models/Company';
import { Country } from './models/Country';
import { Manufacturer } from './models/Manufacturer';
import settingsApi from './settings.api';

type SettingsState = {
    company: Company | undefined;
    country: Country | undefined;
    manufacturers: Manufacturer[] | undefined;
    modules: ModulesConfig | undefined;
};

const initialState: SettingsState = {
    company: undefined,
    country: undefined,
    manufacturers: undefined,
    modules: undefined,
};

const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {},

    extraReducers: builder => {
        builder.addMatcher(
            settingsApi.endpoints.accountSettings.matchFulfilled,
            (state, action) => {
                state.company = action.payload.main_company;
                state.country = action.payload.country;
                state.manufacturers = action.payload.manufacturers;
                state.modules = action.payload.modules;

                // configure helper
                setMeasureSystem(action.payload.country.measure_system);

                // store company timezone on window so it can be accessed when needed
                // cant really put this in state because it needs to be accessed by utils

                window.COMPANY_TIMEZONE = action.payload.main_company.timezone;
            },
        );
    },
});

// export const {  } = slice.actions;

export default slice.reducer;

export const selectCurrentCompany = (state: RootState) => state.settings.company;
export const selectCurrentCountry = (state: RootState) => state.settings.country;

export const selectManufacturer = (manufacturerId: number) => (state: RootState) =>
    state.settings.manufacturers?.find(m => m.id === manufacturerId);
export const selectManufacturers = (state: RootState) => state.settings.manufacturers;
export const selectIsMultiSupplier = (state: RootState) =>
    !!state.settings.manufacturers && state.settings.manufacturers.length > 1;

export const selectDealerModules = (state: RootState) => state.settings.modules?.dealer_portal;

/** Get the default shipping address string based on company config */
export const selectDefaultShippingAddress = (state: RootState) => {
    const company = state.settings.company;
    const deliveryAddress =
        company?.delivery_addresses.find(a => a.is_product_default) ??
        company?.delivery_addresses[0];
    return deliveryAddress?.full_address ?? '';
};
