import { CircularProgress } from '@mui/material';
import Icons from 'Icons';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton, { MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditAddress from 'components/PropertyEditAddress/PropertyEditAddress';
import PropertyEditDate from 'components/PropertyEditDate/PropertyEditDate';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import {
    calculateMinRequiredDate,
    validateProducts,
} from 'features/orders/helpers/orderValidationHelper';
import { OrderWindow } from 'features/orders/models/OrderWindow';
import ordersApi from 'features/orders/orders.api';
import useShippingMethodOptions from 'features/purchases/helpers/useShippingMethodOptions';
import { SalesOrderDetail } from 'features/sales/models/SalesOrderDetail';
import salesApi from 'features/sales/sales.api';
import {
    selectDefaultShippingAddress,
    selectManufacturers,
} from 'features/settings/settings.slice';
import { DateTime } from 'luxon';
import { useBreakpoints } from 'providers/Breakpoints';
import { useDialogManager } from 'providers/DialogManager';
import FormValidation from 'providers/FormValidation';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { formatDateRelative } from 'utils/dateHelpers';
import { formatCurrency } from 'utils/helpers';
import './SalesOrderReviewModal.scss';

export default function SalesOrderReviewModal({
    model,
    windows,
    close,
}: {
    model: SalesOrderDetail;
    windows: OrderWindow[];
    close?: () => void;
}) {
    // need to determine the manufacturer but this order may contain products from multiple manufacturers
    // for now work out which manufacturer is assigned to the first product
    // TODO: rework this for multiple manufacturers

    const brandId = windows[0]?.window_products[0]?.brand_id;
    const manufacturer = useAppSelector(selectManufacturers)?.find(m => m.brands.includes(brandId));
    const shippingMethodOptions = useShippingMethodOptions(manufacturer ?? null);

    const allProducts = useMemo(
        () => windows.flatMap(w => w.window_products) ?? undefined,
        [windows],
    );

    const errors = useMemo(() => {
        const result = [] as string[];
        const productErrors = validateProducts(allProducts);
        if (productErrors.length > 0) {
            result.push('One or more products have errors.');
        }
        return result;
    }, [allProducts]);

    const canSubmit = useMemo(() => {
        if (errors.length > 0) {
            return false;
        }

        if ((model.final_price[0]?.products_selected ?? 0) === 0) {
            return false;
        }
        return true;
    }, [errors.length, model.final_price]);

    /** The current step displayed */
    const [step, setStep] = useState<'form' | 'review'>('form');

    const minRequiredDate = calculateMinRequiredDate(allProducts);
    const [requiredDate, setRequiredDate] = useState(minRequiredDate.toISODate() ?? '');

    const defaultShippingAddress = useAppSelector(selectDefaultShippingAddress);
    const [shippingAddress, setShippingAddress] = useState(defaultShippingAddress);
    const [shippingMethodId, setShippingMethodId] = useState<number | null>(
        manufacturer?.customer_settings.default_shipping_method_id ?? null,
    );

    const shippingMethodAddress = useMemo(() => {
        return manufacturer?.shipping_methods?.find(method => method.id === shippingMethodId)
            ?.address;
    }, [manufacturer?.shipping_methods, shippingMethodId]);

    const dtRequiredDate = useMemo(() => {
        if (!requiredDate) {
            return undefined;
        }
        const dt = requiredDate ? DateTime.fromISO(requiredDate) : undefined;
        return dt;
    }, [requiredDate]);

    const customError = useMemo(() => {
        if (dtRequiredDate && !dtRequiredDate.isValid) {
            return 'Invalid date';
        }
        const min = minRequiredDate ?? DateTime.now().startOf('day').plus({ days: 1 });
        if ((dtRequiredDate?.diff(min).as('milliseconds') ?? 0) < 0) {
            return `Earliest allowed date is ${formatDateRelative(min, { alwaysDate: true })}`;
        }

        return undefined;
    }, [dtRequiredDate, minRequiredDate]);

    const [freightEstimateQuery, freightEstimateQueryStatus] =
        ordersApi.useLazyOrderFreightEstimateQuery();
    const [freightEstimate, setFreightEstimate] = useState<number>();

    const recalculateFreight = useCallback(
        async (props: { orderId: number; shippingMethodId: number; manufacturerId: number }) => {
            const result = await freightEstimateQuery(props).unwrap();
            setFreightEstimate(result);
        },
        [freightEstimateQuery],
    );

    useEffect(() => {
        if (shippingMethodId && manufacturer?.id) {
            recalculateFreight({
                orderId: model.id,
                shippingMethodId,
                manufacturerId: manufacturer?.id,
            });
        } else {
            setFreightEstimate(undefined);
        }
    }, [manufacturer?.id, model.id, recalculateFreight, shippingMethodId]);

    return (
        <MyModal
            className={coalesceClassNames(
                'SalesOrderReviewModal',
                `SalesOrderReviewModal--${step}`,
            )}
            close={close}
            mobileTitle="Sales Order"
            fullHeight
            header={
                <div className="SalesOrderReviewModal__Header__Inner">
                    <PageHeader
                        className="SalesOrderReviewModal__Header__PageHeader"
                        title="Review Order"
                        titleContext={model?.unique_id}
                    />
                    <div className="SalesOrderReviewModal__Header__Error">
                        {!canSubmit && errors.length > 0 && (
                            <MessagePanel messageType="error">
                                <p>Please resolve the following issues before submitting</p>
                                <ul>
                                    {errors.map((err, i) => (
                                        <li key={i}>{err}</li>
                                    ))}
                                </ul>
                            </MessagePanel>
                        )}
                    </div>
                </div>
            }
        >
            {step === 'form' ? (
                <div className="SalesOrderReviewModal__FormStep">
                    <FormValidation submit={() => setStep('review')}>
                        {({ handleSubmit }) => (
                            <>
                                <PropertyContainer className="SalesOrderReviewModal__FormStep__Container">
                                    <PropertyEditDate
                                        label="Required date"
                                        hint={
                                            <>
                                                Based on the products in your order, the earliest
                                                date allowed is{' '}
                                                <strong>
                                                    {formatDateRelative(minRequiredDate, {
                                                        alwaysDate: true,
                                                    })}
                                                </strong>
                                            </>
                                        }
                                        min={minRequiredDate.toISODate() ?? ''}
                                        value={requiredDate}
                                        onChange={val => setRequiredDate(val ?? '')}
                                        validationRequired={'Please specify the required date'}
                                        validationCustom={customError}
                                    />
                                    <PropertyEditSelect
                                        label="Shipping method"
                                        value={`${shippingMethodId ?? ''}`}
                                        options={shippingMethodOptions}
                                        onChange={val => setShippingMethodId(parseInt(val, 10))}
                                        validationRequired
                                        fullWidth
                                    />
                                    {shippingMethodAddress ? (
                                        <div className="SalesOrderReviewModal__StaticAddressDisplay">
                                            <Icons.Location className="icon" />
                                            {shippingMethodAddress}
                                        </div>
                                    ) : (
                                        <PropertyEditAddress
                                            label="Shipping address"
                                            value={shippingAddress}
                                            onChange={setShippingAddress}
                                            validationRequired
                                            validateWithTaxJar
                                        />
                                    )}

                                    <PropertyDisplay
                                        label="Estimated freight"
                                        value={
                                            freightEstimateQueryStatus.isFetching ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                formatCurrency(freightEstimate)
                                            )
                                        }
                                    />
                                </PropertyContainer>

                                <div className="SalesOrderReviewModal__FormStep__Footer">
                                    <MyButton
                                        label="Cancel"
                                        buttonType="Hollow"
                                        onClick={close}
                                    />
                                    <MyButton
                                        label="Next"
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </>
                        )}
                    </FormValidation>
                </div>
            ) : (
                <ReviewStep
                    model={model}
                    requiredDate={requiredDate}
                    shippingAddress={shippingMethodAddress || shippingAddress}
                    shippingMethodId={shippingMethodId ?? 0}
                    canSubmit={canSubmit}
                    goBack={() => setStep('form')}
                    close={close}
                />
            )}
        </MyModal>
    );
}

function ReviewStep({
    model,
    requiredDate,
    shippingAddress,
    shippingMethodId,
    canSubmit,
    goBack,
    close,
}: {
    model: SalesOrderDetail;
    shippingMethodId: number;
    requiredDate: string;
    shippingAddress: string;
    canSubmit: boolean;
    goBack: () => void;
    close?: () => void;
}) {
    const dialogManager = useDialogManager();
    const breakpoints = useBreakpoints();
    const [submitMutation] = salesApi.useSalesOrderSubmitMutation();
    const [generatePdfQuery] = salesApi.useLazySalesOrderGeneratePdfQuery();
    const [pdfUrl, setPdfUrl] = useState<string>();

    const generatePdf = useCallback(async () => {
        const url = await generatePdfQuery({
            orderId: model.id,
            shippingMethodId,
            shippingAddress,
            requiredDate,
        }).unwrap();
        setPdfUrl(url);
    }, [generatePdfQuery, model.id, shippingMethodId, shippingAddress, requiredDate]);

    useEffect(() => {
        generatePdf();
    }, [generatePdf]);

    const submit = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Place order',
            message: 'Quotes cannot be edited after order is placed. Are you sure?',
            acceptLabel: 'Yes, place order',
            acceptButtonType: 'Accent',
        });
        if (confirm) {
            await dialogManager.showLoadingWhile(
                submitMutation({
                    orderId: model.id,
                    requiredDate,
                    shippingAddress,
                    shippingMethodId,
                }).unwrap(),
            );
            close?.();
        }
    }, [
        close,
        dialogManager,
        model.id,
        requiredDate,
        shippingAddress,
        shippingMethodId,
        submitMutation,
    ]);

    const [isIframeLoading, setIsIframeLoading] = useState(true);

    return (
        <div className="SalesOrderReviewModal__ReviewStep">
            {isIframeLoading && (
                <div className="SalesOrderReviewModal__ReviewStep__Loading">
                    <MyLinearProgress delay={0} />
                </div>
            )}
            {pdfUrl && (
                <iframe
                    className="SalesOrderReviewModal__ReviewStep__ContentFrame"
                    src={`${pdfUrl}#toolbar=0`}
                    onLoad={() => setIsIframeLoading(false)}
                />
            )}

            <div className="SalesOrderReviewModal__ReviewStep__Footer">
                <MyButton
                    label="Back"
                    buttonType="Nude"
                    IconLeft={Icons.ChevronLeft}
                    onClick={goBack}
                />
                <div className="SalesOrderReviewModal__ReviewStep__Footer__Primary">
                    <MyButton
                        label="Download Quote"
                        buttonType="Hollow"
                        href={pdfUrl}
                        LinkComponent={MyButtonLinkNewTab}
                        IconLeft={Icons.Download}
                        fullWidth={breakpoints.isVerySmallOnly}
                    />
                    <MyButton
                        className="SalesOrderReviewModal__Footer__SubmitButton"
                        label="Place order"
                        buttonType="Accent"
                        onClick={submit}
                        disabled={!canSubmit}
                        fullWidth={breakpoints.isVerySmallOnly}
                    />
                </div>
            </div>
        </div>
    );
}
