import React, { createContext, useContext } from 'react';
import WorkflowType from '../enums/WorkflowType';

type DealPresentationData = {
    workflowType: WorkflowType;
    routeBase: string;
    topLevelPageTitle: string;
    entityTitle: string;
};

export const DealPresentationContext = createContext<DealPresentationData | undefined>(undefined);
export const useDealPresentation = () => {
    const ctx = useContext(DealPresentationContext);
    if (!ctx) {
        throw new Error(
            'DealPresentationContext not found in the tree. Make sure to wrap your component with DealPresentation.',
        );
    }
    return ctx;
};

const configs: Record<WorkflowType, DealPresentationData> = {
    [WorkflowType.Sales]: {
        workflowType: WorkflowType.Sales,
        routeBase: '/sales',
        topLevelPageTitle: 'Sales',
        entityTitle: 'Sale',
    },
    [WorkflowType.Warranty]: {
        workflowType: WorkflowType.Warranty,
        routeBase: '/warranties',
        topLevelPageTitle: 'Warranties',
        entityTitle: 'Warranty',
    },
    [WorkflowType.Generic]: {
        workflowType: WorkflowType.Generic,
        routeBase: '/deals',
        topLevelPageTitle: 'Deals',
        entityTitle: 'Deal',
    },
} as const;

/** This provider allows us to make components common to both Sales and Purchases without passing in loads of context parameters */
export default function DealPresentation({
    workflowType,
    children,
}: {
    workflowType: WorkflowType;
    children?: React.ReactFragment;
}) {
    const ctx: DealPresentationData = configs[workflowType];

    return (
        <DealPresentationContext.Provider value={ctx}>{children}</DealPresentationContext.Provider>
    );
}
