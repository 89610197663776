import { selectCurrentUser } from 'features/auth/auth.slice';
import { DateTime } from 'luxon';
import { ApiTagType, api } from 'services/api';
import { z } from 'zod';
import { Deal, DealSchema } from './models/Deal';
import { DealDetail, DealDetailSchema } from './models/DealDetail';
import { DealEdit } from './models/DealEdit';
import { DealNote, DealNoteFactory, DealNoteSchema } from './models/DealNote';
import { FlagValue } from './models/FlagValue';
import { Workflow, WorkflowSchema } from './models/Workflow';
import { WorkflowDetail, WorkflowDetailSchema } from './models/WorkflowDetail';

const dealsApi = api.injectEndpoints({
    endpoints: build => ({
        workflowList: build.query<Workflow[], void>({
            query: () => ({
                url: '/workflows',
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.array(WorkflowSchema);
                return schema.parse(result);
            },
            providesTags: [ApiTagType.Workflow],
        }),

        workflowDetail: build.query<WorkflowDetail, string>({
            query: id => ({
                url: `/workflows/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => WorkflowDetailSchema.parse(result),
            providesTags: [ApiTagType.Workflow],
        }),

        workflowDealList: build.query<Deal[], string>({
            query: id => ({
                url: `/workflows/${id}/deals`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.array(DealSchema);
                return schema.parse(result);
            },
            providesTags: [ApiTagType.Workflow, ApiTagType.Deal],
        }),

        dealDetail: build.query<DealDetail, string>({
            query: id => ({
                url: `/deals/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => DealDetailSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.Deal, id }],
        }),

        dealCreate: build.mutation<string, DealEdit>({
            query: model => ({
                url: `/workflows/${model.workflowId}/deals`,
                method: 'POST',
                data: {
                    statusId: model.statusId,
                    customerName: model.customerName,
                    description: model.description,
                },
            }),
            invalidatesTags: [ApiTagType.Deal],
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    id: z.string(),
                });
                return schema.parse(result).id;
            },
        }),

        dealUpdate: build.mutation<void, DealEdit>({
            query: model => ({
                url: `/deals/${model.id}`,
                method: 'POST',
                data: {
                    statusId: model.statusId,
                    customerName: model.customerName,
                    description: model.description,
                    currentStatusId: model.currentStatusId,
                },
            }),
            invalidatesTags: [ApiTagType.Deal],
        }),

        dealMove: build.mutation<
            void,
            {
                dealId: string;
                targetStatusId: string;
                /** deal id of the item above it in the same column or null if moving to the top */
                belowId: string | null;
                currentStatusId: string;
                currentSortOrder: string;
                workflowId: string;
            }
        >({
            query: args => ({
                url: `/deals/${args.dealId}/move`,
                method: 'POST',
                data: args,
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                // Optimistic update of deal object
                const changeset = dispatch(
                    dealsApi.util.updateQueryData('workflowDealList', args.workflowId, deals => {
                        // update all fields of the current detail model
                        moveDeal({
                            dealId: args.dealId,
                            deals,
                            targetStatusId: args.targetStatusId,
                            belowId: args.belowId,
                        });
                        // Object.assign(draft, model);
                    }),
                );

                try {
                    await queryFulfilled;
                } catch (error) {
                    changeset.undo();
                }

                // invalidate list view
                dispatch(dealsApi.util.invalidateTags([ApiTagType.Deal]));
            },
            // TODO Handle 409?
            // invalidatesTags: [ApiTagType.Deal],
        }),

        dealArchive: build.mutation<void, string>({
            query: id => ({
                url: `/deals/${id}/archive`,
                method: 'POST',
            }),
            invalidatesTags: [ApiTagType.Deal],
        }),

        dealNoteList: build.query<DealNote[], string>({
            query: id => ({
                url: `/deals/${id}/notes`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.array(DealNoteSchema);
                return schema.parse(result);
            },
            providesTags: (res, err, dealId) => [
                { type: ApiTagType.Deal, id: dealId },
                { type: ApiTagType.DealNotes, id: dealId },
            ],
        }),

        dealNoteAdd: build.mutation<
            void,
            {
                dealId: string;
                content: string;
            }
        >({
            query: args => ({
                url: `/deals/${args.dealId}/notes`,
                method: 'POST',
                data: {
                    content: args.content,
                },
            }),
            async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
                await queryFulfilled;

                const user = selectCurrentUser(getState() as any);
                const note = DealNoteFactory.create({
                    content: args.content,
                    context: {
                        createdBy: {
                            firstName: user?.first_name ?? '',
                            lastName: user?.last_name ?? '',
                            id: user?.id ?? 0,
                        },
                        updatedBy: null,
                    },
                });

                // Pessimistic update - add to list
                dispatch(
                    dealsApi.util.updateQueryData('dealNoteList', args.dealId, list => {
                        list.push(note);
                    }),
                );

                // invalidate notes list
                dispatch(
                    dealsApi.util.invalidateTags([{ type: ApiTagType.DealNotes, id: args.dealId }]),
                );
            },
        }),

        dealNoteUpdate: build.mutation<
            void,
            {
                id: string;
                dealId: string;
                content: string;
            }
        >({
            query: args => ({
                url: `/deals/${args.dealId}/notes/${args.id}/update`,
                method: 'POST',
                data: {
                    content: args.content,
                },
            }),
            async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
                await queryFulfilled;

                const user = selectCurrentUser(getState() as any);

                // Pessimistic update
                dispatch(
                    dealsApi.util.updateQueryData('dealNoteList', args.dealId, list => {
                        const note = list.find(n => n.id === args.id);
                        if (note) {
                            Object.assign(note, {
                                content: args.content,
                                updatedAt: DateTime.now().toISO(),
                                context: {
                                    ...note.context,
                                    updatedBy: {
                                        firstName: user?.first_name,
                                        lastName: user?.last_name,
                                        id: user?.id,
                                    },
                                },
                            });
                        }
                    }),
                );

                // invalidate notes list
                dispatch(
                    dealsApi.util.invalidateTags([{ type: ApiTagType.DealNotes, id: args.dealId }]),
                );
            },
        }),

        dealNoteArchive: build.mutation<
            void,
            {
                id: string;
                dealId: string;
            }
        >({
            query: args => ({
                url: `/deals/${args.dealId}/notes/${args.id}/archive`,
                method: 'POST',
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update - remove from list
                dispatch(
                    dealsApi.util.updateQueryData('dealNoteList', args.dealId, list => {
                        const index = list.findIndex(n => n.id === args.id);
                        if (index > -1) {
                            list.splice(index, 1);
                        }
                    }),
                );

                // invalidate notes list
                dispatch(
                    dealsApi.util.invalidateTags([{ type: ApiTagType.DealNotes, id: args.dealId }]),
                );
            },
        }),

        dealTagUpdate: build.mutation<
            void,
            { workflowId: string; flagId: string; dealId: string; value: FlagValue }
        >({
            query: args => ({
                url: `/deals/${args.dealId}/flags/${args.flagId}`,
                method: 'POST',
                data: {
                    value: args.value.id,
                },
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                // optimistic update
                const detailUpdate = dispatch(
                    dealsApi.util.updateQueryData('dealDetail', args.dealId, draft => {
                        const flag = draft.context.flags.find(f => f.id === args.flagId);
                        if (flag) {
                            flag.value = args.value;
                        }
                    }),
                );
                const listUpdate = dispatch(
                    dealsApi.util.updateQueryData('workflowDealList', args.workflowId, list => {
                        const deal = list.find(d => d.id === args.dealId);
                        const flag = deal?.context.flags.find(t => t.id === args.flagId);
                        if (flag) {
                            flag.value = args.value;
                        }
                    }),
                );

                try {
                    await queryFulfilled;
                } catch (e) {
                    detailUpdate.undo();
                    listUpdate.undo();

                    dispatch(
                        dealsApi.util.invalidateTags([
                            { type: ApiTagType.Deal, id: args.dealId },
                            { type: ApiTagType.Workflow, id: args.workflowId },
                        ]),
                    );
                    throw e;
                }
            },
        }),
    }),
});

export default dealsApi;

/** Optimistic update function used when calling /deal/id/move */
export function moveDeal({
    dealId,
    deals,
    targetStatusId,
    belowId,
}: {
    dealId: string;
    deals: Deal[];
    targetStatusId: string;
    belowId: string | null;
}) {
    // find deal in collection
    const deal = deals.find(d => d.id === dealId);
    if (!deal) {
        throw new Error(`Deal with id '${dealId}' not found`);
    }

    // find the below deal
    const belowDeal = belowId ? deals.find(d => d.id === belowId) : undefined;
    const belowIndex = belowDeal ? deals.indexOf(belowDeal) : -1;

    // move deal to new position
    const index = deals.indexOf(deal);
    deals.splice(index, 1);
    deals.splice(belowIndex + 1, 0, deal);

    // update status
    deal.statusId = targetStatusId;
}
