import AppHeader from 'components/AppHeader/AppHeader';
import MainNav from 'components/MainNav/MainNav';
import FeatureFlag from 'enums/FeatureFlag';
import dealsApi from 'features/deals/deals.api';
import { selectDealerModules } from 'features/settings/settings.slice';
import useRestoreSession from 'hooks/useRestoreSession';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { useFeatureFlagEnabled } from 'utils/posthogHelpers';
import './Layout.scss';

export default function Layout() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const breakpoints = useBreakpoints();

    useEffect(() => {
        if (!breakpoints.isSmallDown) {
            setIsMenuOpen(false);
        }
    }, [breakpoints.isSmallDown]);

    const sessionRestore = useRestoreSession();

    // Load workflows so we can render the appropriate main nav items
    // This should probably happen as part of useRestoreSession
    // but until BE is done that means you can't log in
    // So putting it here for now.
    const isDealsEnabled = !!useFeatureFlagEnabled(FeatureFlag.Deals);
    const dealerModules = useAppSelector(selectDealerModules);
    const shouldLoadWorkflows = dealerModules?.sales && isDealsEnabled;
    dealsApi.useWorkflowListQuery(undefined, {
        skip: !shouldLoadWorkflows,
    });

    return (
        <>
            {/* Side nav bar */}
            <MainNav
                isLoading={sessionRestore.isLoading}
                isMenuOpen={isMenuOpen}
                closeMenu={() => setIsMenuOpen(false)}
            />

            {sessionRestore.isSuccess && (
                <div className="Layout__Container">
                    <AppHeader onMenuClick={() => setIsMenuOpen(!isMenuOpen)} />
                    <main className="Layout__Main">
                        <Outlet />
                    </main>
                </div>
            )}
        </>
    );
}

/** Add a class to Layout__Container which turns it into a full-screen layout ready for custom scrolling within panels etc
 * A good example of this is for the Kanban board where each column is individually scrollable
 */
export function useFullscreenLayout() {
    useEffect(() => {
        document
            .querySelector('.Layout__Container')
            ?.classList.add('Layout__Container--FullScreen');
        return () => {
            document
                .querySelector('.Layout__Container')
                ?.classList.remove('Layout__Container--FullScreen');
        };
    }, []);
}
