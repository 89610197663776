import { MySelectInputOption } from 'components/MySelectInput/MySelectInput';
import { useMemo } from 'react';
import WorkflowPhase from '../enums/WorkflowPhase';
import { WorkflowDetail } from '../models/WorkflowDetail';

export default function useWorkflowStatusOptions(workflow: WorkflowDetail, phase: WorkflowPhase) {
    const options = useMemo(
        (): MySelectInputOption[] | undefined =>
            workflow.context.statuses
                .filter(s => s.phase === phase)
                .map(s => ({
                    label: s.name,
                    value: s.id,
                })),
        [phase, workflow.context.statuses],
    );
    return options;
}
