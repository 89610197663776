import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import dealsApi from 'features/deals/deals.api';
import { DealDetail } from 'features/deals/models/DealDetail';
import { DealNote, DealNoteFactory } from 'features/deals/models/DealNote';
import { FlagValue } from 'features/deals/models/FlagValue';
import { WorkflowDetail } from 'features/deals/models/WorkflowDetail';
import { useDealPresentation } from 'features/deals/providers/DealPresentation';
import Icons from 'Icons';
import { useBreakpoints } from 'providers/Breakpoints';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import DealEditModal from '../DealEditModal/DealEditModal';
import DealFlagSelect from '../DealFlagSelect/DealFlagSelect';
import DealNoteEditModal from '../DealNoteEditModal/DealNoteEditModal';
import WorkflowStatusIcon from '../WorkflowStatusIcon/WorkflowStatusIcon';
import './DealDetailModal.scss';

export default function DealDetailModal({
    model,
    workflow,
    isLoading,
    isError,
    close,
}: {
    model?: DealDetail;
    workflow?: WorkflowDetail;
    isLoading: boolean;
    isError: boolean;
    close: () => void;
}) {
    const { topLevelPageTitle, entityTitle } = useDealPresentation();
    const [isEditing, setIsEditing] = useState(false);

    const dialogManager = useDialogManager();
    const [archiveMutation] = dealsApi.useDealArchiveMutation();

    const archiveDeal = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: `Archive ${entityTitle.toLowerCase()}`,
            message: `Are you sure you want to archive this ${entityTitle.toLowerCase()}?`,
            acceptButtonType: 'Danger',
            acceptLabel: 'Yes, archive now',
        });

        if (model && confirm) {
            await dialogManager.showLoadingWhile(archiveMutation(model.id).unwrap());
            close?.();
        }
    }, [archiveMutation, close, dialogManager, entityTitle, model]);

    const menuItems: MyMenuButtonItem[] = useMemo(
        () => [
            {
                label: 'Archive',
                IconLeft: Icons.Archive,
                onClick: archiveDeal,
            },
        ],
        [archiveDeal],
    );

    return (
        <>
            <MyModal
                className="DealDetailModal"
                close={close}
                isLoading={isLoading}
                isError={isError}
                mobileTitle={topLevelPageTitle}
                fullHeight
                header={
                    <PageHeader
                        className="DealDetailModal__PageHeader"
                        title={entityTitle}
                        titleContext={model?.tuid}
                    >
                        <MyButton
                            label="Edit"
                            IconLeft={Icons.Edit}
                            buttonType="Hollow"
                            onClick={() => setIsEditing(true)}
                        />
                        <MyMenuKebabButton menuItems={menuItems} />
                    </PageHeader>
                }
            >
                {model && workflow && (
                    <>
                        <div className="DealDetailModal__Details">
                            <PropertyContainer layout="table">
                                <PropertyDisplay
                                    label="Status"
                                    value={
                                        <div className="DealDetailModal__Status">
                                            <WorkflowStatusIcon status={model?.context.status} />
                                            {model?.context.status.name}
                                        </div>
                                    }
                                />
                                <PropertyDisplay
                                    label="Customer"
                                    value={model?.customerName}
                                />
                                <PropertyDisplay
                                    label="Description"
                                    value={model?.description}
                                />
                            </PropertyContainer>

                            <Flags
                                model={model}
                                workflow={workflow}
                            />

                            <Notes dealId={model.id} />

                            <div className="DealDetailModal__Related">
                                <h2 className="DealDetailModal__SectionTitle">Related deals</h2>
                                TODO
                            </div>

                            <div className="DealDetailModal__Payments">
                                <h2 className="DealDetailModal__SectionTitle">Payments</h2>
                                TODO
                            </div>
                        </div>
                    </>
                )}
            </MyModal>

            {model && isEditing && (
                <DealEditModal
                    model={model}
                    close={() => setIsEditing(false)}
                />
            )}
        </>
    );
}

function Flags({ model, workflow }: { model: DealDetail; workflow: WorkflowDetail }) {
    const breakpoints = useBreakpoints();
    const [saveMutation] = dealsApi.useDealTagUpdateMutation();

    const saveFlagValue = useCallback(
        (flagId: string, flagValue?: FlagValue) => {
            if (flagValue) {
                saveMutation({
                    workflowId: workflow.id,
                    dealId: model.id,
                    flagId,
                    value: flagValue,
                });
            }
        },
        [model.id, saveMutation, workflow.id],
    );

    return (
        <div className="DealDetailModal__Flags">
            <PropertyContainer layout={breakpoints.isVerySmallOnly ? 'table' : 'column'}>
                {workflow.context.flags.map(flag => {
                    const value = model.context.flags.find(t => t.id === flag.id)?.value?.id;
                    return (
                        <PropertyDisplay
                            key={flag.id}
                            label={flag.name}
                            value={
                                <DealFlagSelect
                                    label={flag.name}
                                    value={value}
                                    flag={flag}
                                    handleInput={val => saveFlagValue(flag.id, val)}
                                />
                            }
                        />
                    );
                })}
            </PropertyContainer>
        </div>
    );
}

function Notes({ dealId }: { dealId: string }) {
    const query = dealsApi.useDealNoteListQuery(dealId);

    const [editNoteId, setEditNoteId] = useState<string>();
    const editModel = useMemo((): DealNote | undefined => {
        if (editNoteId === 'add') {
            return DealNoteFactory.create();
        }

        return editNoteId ? query.data?.find(n => n.id === editNoteId) : undefined;
    }, [editNoteId, query.data]);

    return (
        <div className="DealDetailModal__Notes">
            <h2 className="DealDetailModal__SectionTitle">Notes</h2>
            {query.isError ? (
                <ErrorContent />
            ) : query.isLoading ? (
                <MyLinearProgress />
            ) : (
                <>
                    <div className="DealDetailModal__Notes__List">
                        {query.data?.map(n => {
                            const user = n.context.updatedBy ?? n.context.createdBy;
                            return (
                                <div
                                    key={n.id}
                                    className="DealDetailModal__Note"
                                >
                                    <div className="DealDetailModal__Note__Content">
                                        {n.content}
                                    </div>
                                    <div className="DealDetailModal__Note__Footer">
                                        <div className="DealDetailModal__Note__Footer__Author">
                                            {user?.firstName} {user?.lastName}
                                        </div>
                                        <div className="DealDetailModal__Note__Footer__Date">
                                            {formatDateTimeRelative(n.updatedAt ?? n.createdAt)}
                                        </div>
                                        <a
                                            className="Link"
                                            onClick={() => setEditNoteId(n.id)}
                                        >
                                            Edit
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <MyButton
                        className="DealDetailModal__Notes__AddButton"
                        IconLeft={Icons.Plus}
                        label="Add note"
                        buttonType="Hollow"
                        size="small"
                        onClick={() => setEditNoteId('add')}
                    />
                </>
            )}

            {editModel && (
                <DealNoteEditModal
                    dealId={dealId}
                    model={editModel}
                    close={() => setEditNoteId(undefined)}
                />
            )}
        </div>
    );
}
