import { faker } from '@faker-js/faker';
import PurchaseOrderStatus from '../enums/PurchaseOrderStatus';
import { PurchaseOrderDetail } from '../models/PurchaseOrderDetail';

export const generateFakePurchaseOrderDetails = (): PurchaseOrderDetail => {
    return {
        id: faker.number.int(),
        tuid: `O-${faker.number.int({ min: 10000, max: 99999 })}`,
        status: faker.helpers.enumValue(PurchaseOrderStatus),
        createdAt: faker.date.past().toISOString(),
        manufacturerId: faker.helpers.arrayElement([3, 28]),
        isArchived: false,

        isPurchase: true,
        isSale: false,
        isReadOnly: false,

        // Editable fields
        salesRep: faker.person.fullName(),
        sidemark: faker.person.lastName().toUpperCase(),
        customerDetails: faker.location.streetAddress(),
        notes: '',

        // Pricing fields
        totalCost: faker.number.float({ min: 10, max: 10000, precision: 0.01 }),
        totalQuantity: faker.number.int({ min: 1, max: 100 }),
        context: {
            parentOrder: undefined,
            latestRevision: undefined,
            orderDocument: undefined,
            shippingAddress: '',
            requiredDate: null,
            pendingUpdates: {
                hasPendingUpdates: false,
                orderUpdates: {
                    hasSurchargesUpdates: false,
                    hasFreightUpdates: false,
                    hasInstallationUpdates: false,
                },
                products: [
                    //    {
                    //     productId: false,
                    //     productName: false,
                    //     hasCostPaddingUpdates: false,
                    //     hasFabricUpdates: false,
                    //     hasFreightUpdates: false,
                    //     hasInstallationUpdates: false,
                    //     hasRuleUpdates: false,
                    //     hasSurchargesUpdates: false,
                    //     hasPricingUpdates: false,
                    //     },
                ],
            },
        },
    };
};

export const fakePurchaseOrderDetails = faker.helpers.multiple(generateFakePurchaseOrderDetails, {
    count: 400,
});
