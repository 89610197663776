import Icons from 'Icons';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import OrderPresentation from 'features/orders/providers/OrderPresentation';
import PurchasesTable from 'features/purchases/components/PurchasesTable/PurchasesTable';
import { selectDealerModules } from 'features/settings/settings.slice';
import React from 'react';
import { Outlet } from 'react-router';
import { useAppSelector } from 'store/hooks';
import { usePageTitle } from 'utils/locationHelpers';

export default function PurchaseOrdersPage() {
    const dealerModules = useAppSelector(selectDealerModules);
    usePageTitle(dealerModules?.sales ? 'Purchases' : 'Orders');

    return (
        <OrderPresentation isSales={false}>
            <PageHeader title={dealerModules?.sales ? 'Purchases' : 'Orders'}>
                <MyButton
                    label="New order"
                    IconLeft={Icons.Plus}
                    buttonType="Accent"
                    href="new"
                    LinkComponent={MyButtonLink}
                />
            </PageHeader>

            <PurchasesTable />

            <Outlet />
        </OrderPresentation>
    );
}
