import z from 'zod';
import { FlagValueSchema } from './FlagValue';

export const DealFlagSchema = z.object({
    id: z.string(),
    name: z.string(),
    value: FlagValueSchema,
});

export type DealFlag = z.infer<typeof DealFlagSchema>;
