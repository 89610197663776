import { Autocomplete, FormControl, TextField, Tooltip } from '@mui/material';
import FieldValidator from 'components/FieldValidator/FieldValidator';
import MyButton from 'components/MyButton/MyButton';
import Icons from 'Icons';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import regexs from 'utils/regexs';
import './MyMultiEmailInput.scss';

export type MyMultiEmailInputProps = {
    value: string;
    disabled?: boolean;
    readonly?: boolean;
    handleInput?: (val: string) => void;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
    validationKey?: string;
    className?: string;
};

export default function MyMultiEmailInput({
    value,
    disabled = false,
    readonly = false,
    handleInput,
    validationRequired,
    validationCustom,
    validationKey,
    className,
}: MyMultiEmailInputProps) {
    const selectedEmails = useMemo(
        () =>
            value
                .split(',')
                .filter(Boolean)
                .map(e => e.trim()),
        [value],
    );

    const handleChange = useCallback(
        (emails: string[]) => {
            const val = emails.join(',');
            handleInput?.(val);
        },
        [handleInput],
    );

    const validationError = useMemo(() => {
        const isValid = selectedEmails.every(email => email.match(regexs.emailAllowBlank));
        if (!isValid) {
            return 'Email address is invalid';
        }

        return undefined;
    }, [selectedEmails]);

    return (
        <FieldValidator
            validationKey={validationKey}
            validationRequired={validationRequired}
            validationCustom={validationCustom || validationError}
            value={value}
        >
            {({ revealError }) => (
                <FormControl
                    className={coalesceClassNames('MyMultiEmailInput', className)}
                    fullWidth
                >
                    <Autocomplete
                        className={coalesceClassNames(
                            'MyMultiEmailInput__Autocomplete',
                            className && `${className}__Autocomplete`,
                        )}
                        fullWidth
                        readOnly={readonly}
                        disabled={disabled}
                        size="small"
                        options={[]}
                        value={selectedEmails}
                        multiple={true}
                        freeSolo={true}
                        autoSelect={true}
                        renderInput={params => (
                            <TextField
                                {...params}
                                id={'myInput'}
                                className="MyMultiEmailInput__TextField"
                            />
                        )}
                        renderTags={(val, getTagProps) =>
                            val.map((email, index) => {
                                const tagProps = getTagProps({ index });
                                return (
                                    <EmailChip
                                        key={index}
                                        value={email}
                                        onDelete={tagProps.onDelete}
                                    />
                                );
                            })
                        }
                        disableCloseOnSelect={true}
                        onChange={(e, opts) => handleChange(opts)}
                        onBlur={revealError}
                        onClose={revealError}
                    />
                </FormControl>
            )}
        </FieldValidator>
    );
}

function EmailChip({ value, onDelete }: { value: string; onDelete?: (event: any) => void }) {
    const isValid = value.match(regexs.email);

    const dialogManager = useDialogManager();

    const copyValue = useCallback(() => {
        navigator.clipboard.writeText(value);
        dialogManager.toast({
            message: 'Copied to clipboard',
        });
    }, [dialogManager, value]);

    return (
        <Tooltip
            title="Click to copy value"
            placement="top"
            enterDelay={1000}
        >
            <div
                className={coalesceClassNames(
                    'MyMultiEmailInput__EmailChip',
                    isValid
                        ? 'MyMultiEmailInput__EmailChip--valid'
                        : 'MyMultiEmailInput__EmailChip--invalid',
                )}
            >
                {value}
                <MyButton
                    className="MyMultiEmailInput__EmailChip__Button"
                    IconLeft={Icons.Copy}
                    size="small"
                    buttonType="None"
                    onClick={copyValue}
                />
                <MyButton
                    className="MyMultiEmailInput__EmailChip__Button"
                    IconLeft={Icons.Clear}
                    size="small"
                    buttonType="None"
                    onClick={onDelete}
                />
            </div>
        </Tooltip>
    );
}
