import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

type PurchasesState = {
    lastUsedManufacturerId?: number;
};

const getInitialState = (): PurchasesState => {
    const lastUsedManufacturerId = localStorage.getItem('lastUsedManufacturerId') ?? '';
    return {
        lastUsedManufacturerId: parseInt(lastUsedManufacturerId, 10) || undefined,
    };
};

const slice = createSlice({
    name: 'purchases',
    initialState: getInitialState(),
    reducers: {
        setLastUsedManufacturerId(state, action: PayloadAction<number | undefined>) {
            if (action.payload) {
                localStorage.setItem('lastUsedManufacturerId', `${action.payload}`);
            } else {
                localStorage.removeItem('lastUsedManufacturerId');
            }
            state.lastUsedManufacturerId = action.payload;
        },
    },
    // extraReducers: builder => {},
});

export const { setLastUsedManufacturerId } = slice.actions;

export default slice.reducer;

export const selectLastUsedManufacturerId = (state: RootState) => {
    // there should always be at least one manufacturer
    // if there isn't, the app is in a bad state
    const result = state.purchases.lastUsedManufacturerId ?? state.settings.manufacturers?.[0]?.id;
    if (result) {
        return result;
    }
    throw new Error('selectLastUsedManufacturerId: No manufacturers found');
};
