import z from 'zod';
import { Product } from './Product';

export const OrderWindowProductNewSchema = z.object({
    order_id: z.number(),
    product_id: z.number(),
    brand_id: z.number(),
    category_id: z.number(),
    details: z.string().nullable(),
    notes: z.string().nullable(),
    quantity: z.number(),
    width: z.number().optional(),
    height: z.number().optional(),
    has_width: z.number().default(1),
    has_height: z.number().default(1),
    has_quantity: z.number().default(1),
});

export type OrderWindowProductNew = z.infer<typeof OrderWindowProductNewSchema>;

export class OrderWindowProductNewFactory {
    static create({ orderId, product }: { orderId: number; product: Product }) {
        const model: OrderWindowProductNew = {
            order_id: orderId,
            product_id: product.id,
            brand_id: product.brand_id,
            category_id: product.category_id,
            details: '',
            notes: '',
            quantity: 1,
            width: product.has_width ? undefined : 0,
            height: product.has_height ? undefined : 0,
            has_width: product.has_width,
            has_height: product.has_height,
            has_quantity: product.has_quantity,
        };

        return model;
    }
}
