import Icons from 'Icons';
import DataTableFilters, {
    DataTableFilterConfig,
} from 'components/DataTableFilters/DataTableFilters';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyButton from 'components/MyButton/MyButton';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import { OrderWindow } from 'features/orders/models/OrderWindow';
import {
    OrderWindowProductNew,
    OrderWindowProductNewFactory,
} from 'features/orders/models/OrderWindowProductNew';
import { Product } from 'features/orders/models/Product';
import ordersApi from 'features/orders/orders.api';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OrderProductEditFields from '../OrderProductEditFields/OrderProductEditFields';
import ProductPathDisplay from '../ProductPathDisplay/ProductPathDisplay';
import './OrderProductAddModal.scss';

export default function OrderProductAddModal({
    orderId,
    windows,
    manufacturerId,
    close,
}: {
    orderId: number;
    windows: OrderWindow[];
    manufacturerId?: number;
    close?: () => void;
}) {
    const { entityTitle } = useOrderPresentation();

    const productsQuery = ordersApi.useProductListQuery({ manufacturerId });
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [filteredProducts, setFilteredProducts] = useState<Product[]>();

    const goBack = useCallback(() => {
        if (selectedProduct) {
            setSelectedProduct(undefined);
        } else {
            close?.();
        }
    }, [close, selectedProduct]);

    const filters: DataTableFilterConfig[] = useMemo(
        () => [
            {
                label: 'Search',
                type: 'search',
                fields: ['brand_name', 'category_name', 'name'],
                defaultValue: '',
            },
        ],
        [],
    );

    /** A list of Products already in this order */
    const quickLinkProducts: Product[] = useMemo(() => {
        const allProducts = windows.flatMap(w => w.window_products) ?? [];
        const uniqueProducts = Array.from(
            new Map(allProducts.map(p => [p.product_id, p])).values(),
        );

        return (
            productsQuery.data?.filter(
                p => uniqueProducts.findIndex(up => up.product_id === p.id) > -1,
            ) ?? []
        );
    }, [productsQuery.data, windows]);

    /** Only show quick links when products are unfiltered (i.e user has not entered any search text) */
    const showQuickLinks = useMemo(
        () =>
            quickLinkProducts.length > 0 && filteredProducts?.length === productsQuery.data?.length,
        [filteredProducts?.length, productsQuery.data?.length, quickLinkProducts.length],
    );

    return (
        <MyModal
            className="OrderProductAddModal"
            close={goBack}
            mobileTitle={entityTitle}
            header={
                <PageHeader
                    className="OrderProductAddModal__PageHeader"
                    title="Product search"
                    subtitle="Search for a product to add to your order"
                />
            }
        >
            <DataTableFilters
                className="OrderProductAddModal__Filters"
                data={productsQuery.data}
                filters={filters}
                onChange={setFilteredProducts}
                allowReset={false}
            />
            <div className="OrderProductAddModal__ProductList">
                {productsQuery.isLoading ? (
                    <div className="OrderProductAddModal__ProductList__Loading">
                        <MyLinearProgress />
                    </div>
                ) : productsQuery.isError ? (
                    <ErrorContent className="OrderProductAddModal__ProductList__Error" />
                ) : filteredProducts?.length === 0 ? (
                    <div className="OrderProductAddModal__ProductList__Empty">
                        No products found
                    </div>
                ) : (
                    <>
                        {showQuickLinks && (
                            <>
                                <div className="OrderProductAddModal__ProductList__Header">
                                    <Icons.CaretDown /> Quick links
                                </div>
                                <div className="OrderProductAddModal__ProductList__Products">
                                    {quickLinkProducts.map(product => (
                                        <ProductButton
                                            key={product.id}
                                            product={product}
                                            onClick={() => setSelectedProduct(product)}
                                        />
                                    ))}
                                </div>
                            </>
                        )}

                        {showQuickLinks && (
                            <div className="OrderProductAddModal__ProductList__Header">
                                <Icons.CaretDown /> All products
                            </div>
                        )}

                        <div className="OrderProductAddModal__ProductList__Products">
                            {filteredProducts?.map(product => (
                                <ProductButton
                                    key={product.id}
                                    product={product}
                                    onClick={() => setSelectedProduct(product)}
                                />
                            ))}
                        </div>
                    </>
                )}
            </div>
            {/* Show form once product has been selected */}
            {selectedProduct && (
                <ProductFormModal
                    orderId={orderId}
                    product={selectedProduct}
                    close={close}
                    goBack={goBack}
                />
            )}
        </MyModal>
    );
}

function ProductButton({ product, onClick }: { product: Product; onClick: () => void }) {
    return (
        <MyButton
            key={product.id}
            className="OrderProductAddModal__ProductButton"
            buttonType="None"
            label={<ProductPathDisplay product={product} />}
            onClick={onClick}
            IconRight={Icons.ChevronRight}
            fullWidth
        />
    );
}

function ProductFormModal({
    orderId,
    product,
    close,
    goBack,
}: {
    orderId: number;
    product: Product;
    close?: () => void;
    goBack?: () => void;
}) {
    const model = useMemo(
        () =>
            OrderWindowProductNewFactory.create({
                orderId,
                product,
            }),
        [orderId, product],
    );

    const { routeBase } = useOrderPresentation();

    const [saveMutation, saveMutationState] = ordersApi.useOrderProductAddMutation();
    const navigate = useNavigate();

    const save = useCallback(
        async (editModel: OrderWindowProductNew) => {
            const productId = await saveMutation(editModel).unwrap();
            navigate(`${routeBase}/${orderId}/products/${productId}`);
            close?.();
        },
        [close, navigate, orderId, saveMutation, routeBase],
    );

    return (
        <MyEditModal
            className="OrderProductAddModal__ProductFormModal"
            title="Add product"
            subtitle={<ProductPathDisplay product={product} />}
            mobileTitle={'Product search'}
            model={model}
            close={goBack}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            editImmediately={true}
            fullHeight={false}
        >
            {({ editModel, updateField, isSaving }) => (
                <OrderProductEditFields
                    editModel={editModel}
                    updateField={updateField}
                    isSaving={isSaving}
                />
            )}
        </MyEditModal>
    );
}
