import z from 'zod';

export const CompanySchema = z.object({
    id: z.number(),
    name: z.string(),
    email: z.string().nullable(),
    timezone: z.string(),
    account_id: z.number(),
    phone: z.string().nullable(),
    mobile: z.string().nullable(),
    fax: z.string().nullable(),
    address: z.string().nullable(),
    delivery_addresses: z
        .array(
            z.object({
                id: z.number(),
                is_product_default: z.number(),
                full_address: z.string(),
            }),
        )
        .default([]),
});

export type Company = z.infer<typeof CompanySchema>;
